/* eslint-disable no-unused-vars */
import axios from "axios";
import Button from "../Button";
import LoadingSpinner from "../LoadingSpinner";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchInventoryData,
  selectInventoryData,
} from "../../redux/reducers/myInventorySlice";

const UpdateInventory = ({ inventory_id, closeModal }) => {
  const dispatch = useDispatch();
  const [product, setProduct] = useState("");
  const [category, setCategory] = useState("");
  const [formulation, setFormulation] = useState("");
  const [qty, setQty] = useState("");
  const [pricing, setPricing] = useState("");
  const [sku, setSku] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const inventoryData = useSelector(selectInventoryData);


  const handleSubmit = async (e) => {
    e.preventDefault();

    const fieldsUpdated =
      product !== "" ||
      category !== "" ||
      formulation !== "" ||
      qty !== "" ||
      pricing !== "" ||
      sku !== "";

    if (!fieldsUpdated) {
      toast.error("Please update at least one field");
      return;
    }

    const data = {
      inventory_id,
    };

    if (product !== "") {
      data.product = product;
    }
    if (category !== "") {
      data.category = category;
    }
    if (formulation !== "") {
      data.formulation = formulation;
    }
    if (qty !== "") {
      data.qty = qty;
    }
    if (pricing !== "") {
      data.pricing = pricing;
    }
    if (sku !== "") {
      data.sku = sku;
    }

    const token = localStorage.getItem("token");
    setIsLoading(true);

    try {
      const response = await axios.put(
        "https://kasdhma.lifebanktest.com/api/admin/product/edit",
        data,
        {
          headers: {
            Authorization: token,
          },
        }
      );

      toast.success("Data successfully updated");
      closeModal();
      dispatch(fetchInventoryData());
    } catch (error) {
      console.error(error);
      toast.error("Error updating data");
    }

    setIsLoading(false);
  };

  useEffect(() => {
    // Fetch the item data based on the inventory_id from inventoryData
    const selectedInventory = inventoryData.find(
      (item) => item.id === inventory_id
    );
    if (selectedInventory) {
      setProduct(selectedInventory.product || "");
      setCategory(selectedInventory.category || "");
      setFormulation(selectedInventory.formulation || "");
      setQty(selectedInventory.qty || "");
      setPricing(selectedInventory.pricing || "");
      setSku(selectedInventory.sku || "");
    }
  }, [inventory_id, inventoryData]);

  return (
    <form onSubmit={handleSubmit} className="w-full max-w-md mx-auto">
      <div className="mb-4">
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="product"
        >
          Product
        </label>
        <input
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="product"
          type="text"
          placeholder="Enter product"
          value={product}
          onChange={(e) => setProduct(e.target.value)}
        />
      </div>
      <div className="mb-4">
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="category"
        >
          Category
        </label>
        <input
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="category"
          type="text"
          placeholder="Enter category"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
        />
      </div>
      <div className="mb-4">
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="formulation"
        >
          Formulation
        </label>
        <input
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="formulation"
          type="text"
          placeholder="Enter formulation"
          value={formulation}
          onChange={(e) => setFormulation(e.target.value)}
        />
      </div>
      <div className="mb-4">
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="quantity"
        >
          Qty. Available
        </label>
        <input
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="quantity"
          type="text"
          placeholder="Enter quantity available"
          value={qty}
          onChange={(e) => setQty(e.target.value)}
        />
      </div>
      <div className="mb-4">
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="price"
        >
          Price
        </label>
        <input
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="price"
          type="text"
          placeholder="Enter price"
          value={pricing}
          onChange={(e) => setPricing(e.target.value)}
        />
      </div>
      <div className="mb-4">
        <label
          className="block text-gray-700 text-sm font-bold mb-2"
          htmlFor="sku"
        >
          SKU
        </label>
        <input
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          id="sku"
          type="text"
          placeholder="Enter SKU"
          value={sku}
          onChange={(e) => setSku(e.target.value)}
        />
      </div>
      <div className="flex items-center justify-center">
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <Button
            color="primary"
            size="large"
            onClick={handleSubmit}
            disabled={isLoading}
          >
            Update Inventory
          </Button>
        )}
      </div>
    </form>
  );
};

export default UpdateInventory;
