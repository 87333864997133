import React, { useEffect, useState } from "react";
import axios from "axios";

const UserInfo = () => {
  const [userInfo, setUserInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const token = localStorage.getItem("token");
  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const response = await axios.get(
          "https://kasdhma.lifebanktest.com/api/admin/user",
          {
            headers: {
              Authorization: `${token}`,
            },
          }
        );

        const userData = response.data.data;
        setUserInfo(userData);
        setIsLoading(false);
      } catch (error) {
        console.log("An error occurred while fetching user info:", error);
        setIsLoading(false);
      }
    };

    fetchUserInfo();
  }, [token]);

  if (isLoading) {
    return <p> </p>;
  }

  const greeting = `${userInfo?.firstname}`;

  return <h1>{greeting}</h1>;
};

export default UserInfo;
