/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Link, Navigate, Route, Routes, useNavigate } from "react-router-dom";
import ProfileSettings from "../ProfileSettings";
import axios from "axios";
import useSortableTable from "../../hooks/useSortableTable";

const PredictionNavigate = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const itemsPerPage = 6;

  const getProduct = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        "https://kasdhma.lifebanktest.com/api/admin/predictions/product",
        {
          headers: {
            Authorization: token,
          },
        }
      );
      console.log("response", response.data.data);
      setData(response.data.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProduct();
  }, []);

  const { facility_name } = data.length > 0 ? data[0] : {};
  const handleSeeMore = (e) => {
    e.preventDefault();

    const totalItems = data.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    setCurrentPage((prevPage) => (prevPage < totalPages ? prevPage + 1 : 1));
  };

  const { sortedData, sortBy, sortDirection, handleSort } = useSortableTable(
    data,
    "id",
    "asc"
  );

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const paginatedData = sortedData.slice(startIndex, endIndex);

  const tableHeaders = [
    { label: "PRODUCT ID", columnName: "id", defaultSortDirection: "asc" },
    { label: "Product name", columnName: "facility_name" },
    { label: "Product Current Qty", columnName: "qty" },
    { label: "Product Predicted Qty", columnName: "predicted" },
    { label: "Difference", columnName: "difference" },
  ];

  const getArrowIcon = (columnName) => {
    if (sortBy === columnName) {
      return sortDirection === "asc" ? "▲" : "▼";
    }
    return "";
  };

  return (
    <>
      <div className="overflow-x-auto">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <Link className="mb-3 md:mb-0" to="">
            {/* Predictions */}
          </Link>

          <ProfileSettings />
        </div>
      </div>
      <div className="flex flex-col md:flex-row justify-between items-center">
        <h2 className="font-semibold text-2xl mb-2 md:mb-0 md:mr-4">
          Current Prediction Requirements
        </h2>
      </div>
      <div className="overflow-x-auto mb-10">
        <div className="min-w-full shadow-lg">
          <table className="w-full text-center shadow-lg">
            <thead className="bg-[#06652e]">
              <tr className="text-[#ffff] text-base">
                {tableHeaders.map((header) => (
                  <th
                    key={header.columnName}
                    className="py-4 px-2 sm:px-4 md:px-6 lg:px-8 cursor-pointer"
                    onClick={() =>
                      header.columnName !== "action"
                        ? handleSort(header.columnName)
                        : undefined
                    }
                  >
                    {header.label}{" "}
                    <span>{getArrowIcon(header.columnName)}</span>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {paginatedData.length === 0 ? (
                <tr>
                  <td
                    colSpan={6}
                    className="text-4xl font-bold text-center mb-2 mt-2 h-28"
                  >
                    No Pending Predictions
                  </td>
                </tr>
              ) : (
                paginatedData.map((item) => (
                  <tr
                    key={item.product}
                    className="border-b border-gray-400 border-opacity-20 bg-[#F8FBFF]"
                  >
                    <td className="py-4 px-2 sm:px-4 md:px-6 lg:px-8">
                      {item.product}
                    </td>
                    <td className="py-4 px-2 sm:px-4 md:px-6 lg:px-8">
                      {item.name}
                    </td>
                    <td className="py-4 px-2 sm:px-4 md:px-6 lg:px-8">
                      {item.qty}
                    </td>
                    <td className="py-4 px-2 sm:px-4 md:px-6 lg:px-8">
                      {item.prediction}
                    </td>
                    <td className="py-4 px-2 sm:px-4 md:px-6 lg:px-8">
                      {item.difference}
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>

      <div className=" flex justify-end items-center mb-20">
        <p
          className="text-[#06652e] hover:underline cursor-pointer text-2xl"
          onClick={handleSeeMore}
        >
          See more - {currentPage} of {Math.ceil(data.length / itemsPerPage)}
        </p>
      </div>
    </>
  );
};

export default PredictionNavigate;
