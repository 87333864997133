import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  signIn,
  updateEmail,
  updatePassword,
} from "../redux/reducers/authSlice";
import { FiEye, FiEyeOff } from "react-icons/fi";
// import Logo from "../assets/Logo.svg";
import oneboxMain from "../assets/oneboxLogoMain.png"
import clientLogo from "../assets/KADSHMA .jpeg"

const SignInPage = () => {
  const email = useSelector((state) => state.auth.email);
  const password = useSelector((state) => state.auth.password);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleEmailChange = (e) => {
    const email = e.target.value;
    dispatch(updateEmail(email));
  };

  const handlePasswordChange = (e) => {
    const password = e.target.value;
    dispatch(updatePassword(password));
  };

  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const { data } = await axios.post(
        "https://kasdhma.lifebanktest.com/api/admin/login",
        {
          email,
          password,
        }
      );

      const token = data.token;
      localStorage.setItem("token", token);

      dispatch(signIn());
      navigate("/dashboard");
      toast.success("Sign-in successful!");
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Invalid credentials");
      } else {
        toast.error("An error occurred");
        console.log("An error occurred", error);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token && !isAuthenticated) {
      dispatch(signIn());
      navigate("/dashboard");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="bg-[#06652e] h-screen">
      <div className="p-8 bg-[##06652e]">
        <div className="flex flex-col items-center ">
          <img 
            src={clientLogo}
            alt=""
            className="mb-5"
            style={{  height: "150px" , objectFit: "cover"}}
          />
          <img
            src={oneboxMain}
            alt=""
            className="mb-5"
            style={{  height: "50px" , objectFit: "cover"}}
          />
          <h1 className="text-3xl font-bold mb-5 text-[#fff]">Welcome back</h1>
          <p className="text-sm mb-5 text-[#fff]">
            Sign in to continue to your account
          </p>
          <div className="p-6 mt-5 pb-10 w-1/3 bg-white shadow-lg rounded-lg">
            <form className="flex flex-col">
              <label htmlFor="username" className="mb-2">
                Email
              </label>
              <input
                type="text"
                id="username"
                value={email}
                onChange={handleEmailChange}
                className="p-2 border border-gray-300 mb-4"
                required
              />

              <label htmlFor="password" className="mb-2">
                Password
              </label>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  id="password"
                  value={password}
                  onChange={handlePasswordChange}
                  className="p-2 border border-gray-300 mb-4 pr-10 w-full"
                  required
                />
                <button
                  type="button"
                  className="absolute right-2 top-1/3 transform -translate-y-1/2 text-gray-500 focus:outline-none"
                  onClick={handleTogglePassword}
                >
                  {showPassword ? <FiEyeOff /> : <FiEye />}
                </button>
              </div>

              <button
                type="submit"
                className="bg-[#06652e] hover:bg-[#06652e] text-white font-bold py-2 px-4 rounded"
                onClick={handleSubmit}
                disabled={isLoading}
              >
                {isLoading ? "Please wait..." : "Sign In"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignInPage;
