/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import axios from "axios";
import PopupNotification from "../notifcations/PopUpNotification";
import LoadingSpinner from "../LoadingSpinner";

const Recalibrate = () => {
  const [notificationMessage, setNotificationMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleRecalibrate = async () => {
    setNotificationMessage(null); 
    setIsLoading(true); 

    const token = localStorage.getItem("token");

    try {
      const response = await axios.get(
        "https://kasdhma.lifebanktest.com/api/admin/recalculate",
        {
          headers: {
            Authorization: token,
          },
        }
      );
      const data = response.data;
      setNotificationMessage("Recalibration completed successfully");
    } catch (error) {
      console.error(error);
      setNotificationMessage("An error occurred.");
    } finally {
      setIsLoading(false); 
    }
  };

  return (
    <div
      style={{
        minWidth: "100px",
        minHeight: "200px",
        width: "100%",
        maxWidth: "400px",
        background: "#2C2E82",
        borderRadius: "10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={handleRecalibrate}
    >
      {isLoading ? ( 
        <LoadingSpinner />
      ) : (
        <button className="bg-[#2C2E82] hover:bg-[#2C2E82] text-white font-bold py-2 px-4 rounded text-xl">
          Recalibrate
        </button>
      )}
      {notificationMessage && (
        <PopupNotification message={notificationMessage} />
      )}
    </div>
  );
};

export default Recalibrate;
